'use client';

import { ClerkProvider } from '@clerk/nextjs';
import { SettingsProvider } from '@/contexts/SettingsContext';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ClerkProvider>
      <SettingsProvider>{children}</SettingsProvider>
    </ClerkProvider>
  );
}
